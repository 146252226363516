@import '~@angular/material/prebuilt-themes/indigo-pink.css';

html, body{
    padding: 0;
    margin: 0;
}

.example-spacer {
    flex: 1 1 auto;
}
